import React, { useState, useEffect } from 'react';

import Menu from '../Menu';

import api from '../../utils/api';

const AuditorMenu = (props) => {

  const { auditor_id } = props.user;

  useEffect(() => {

  }, []);

  return (
    <React.Fragment>
      <ul className="main-menu metismenu">
        <Menu 
          title="Dashboard"
          icon="speedometer"
          to="/" 
        />

        <Menu 
          title="Application Form"
          icon="doc"
          to={`/auditor/application-form/edit/${auditor_id}`}
        />
        <Menu 
          title="Claim"
          icon="calculator"
          to={`/auditor/auditor-claim`}
        >
           <Menu 
              title="Create Claim"
              to="/auditor/auditor-claim/create" 
            />
          <Menu 
              title="Claim List"
              to="/auditor/auditor-claim" 
            />
        </Menu>
        {/* <Menu 
          title="Auditor Evaluation"
          icon="check"
          to="/application-review/auditor-evaluations" 
        /> */}

        <Menu 
          title="Audit"
          icon="check"
          to="/audit-module"
          // onClick={() => setActiveMenu('audit')}
          // activeMenu={activeMenu}
        >
          <Menu 
            title="Calendar"
            to="/audit-module/calendar" 
          />
          <Menu 
            title="Audit Plan"
            // icon="check"
            to="/audit-module/audit-plan"
            // onClick={() => setActiveMenu('audit')}
            // activeMenu={activeMenu}
          >
            <Menu 
              title="ISO"
              to="/audit-module/audit-plan/iso/iso" 
            />
            <Menu 
              title="MSPO Part 2"
              to="/audit-module/audit-plan/mspo/part-2" 
            />
            <Menu 
              title="MSPO Part 2-1"
              to="/audit-module/audit-plan/mspo/part-2-1" 
            />
            <Menu 
              title="MSPO Part 2-2"
              to="/audit-module/audit-plan/mspo/part-2-2" 
            />
            <Menu 
              title="MSPO Part 3"
              to="/audit-module/audit-plan/mspo/part-3" 
            />
            <Menu 
              title="MSPO Part 3-1"
              to="/audit-module/audit-plan/mspo/part-3-1" 
            />
            <Menu 
              title="MSPO Part 3-2"
              to="/audit-module/audit-plan/mspo/part-3-2" 
            />
            <Menu 
              title="MSPO Part 4"
              to="/audit-module/audit-plan/mspo/part-4" 
            />
            <Menu 
              title="MSPO Part 4-1"
              to="/audit-module/audit-plan/mspo/part-4-1" 
            />
            <Menu 
              title="MSPO Part 4-2"
              to="/audit-module/audit-plan/mspo/part-4-2" 
            />
            <Menu 
              title="MSPO Part 4-3"
              to="/audit-module/audit-plan/mspo/part-4-3" 
            />
            {/* <Menu 
              title="MSPO"
              to="/audit-module/audit-plan/mspo" 
            >
              <Menu 
                title="MSPO Part 2"
                to="/audit-module/audit-plan/mspo/part-2" 
              />
              <Menu 
                title="MSPO Part 3"
                to="/audit-module/audit-plan/mspo/part-3" 
              />
              <Menu 
                title="MSPO Part 4"
                to="/audit-module/audit-plan/mspo/part-4" 
              />
            </Menu> */}
            <Menu 
              title="MSPO-SCCS"
              to="/audit-module/audit-plan/mspo-sccs/mspo-sccs" 
            />
            {/* <Menu 
              title="Audit"
              to="/audit/audit" 
            /> */}
          </Menu>

          {/* <Menu 
            title="Audit Notes"
            // icon="list"
            to="/audit-module/audit-notes" 
          /> */}

          <Menu 
            title="Audit Report"
            //icon="doc"
            to="/audit-module/audit-report"
            // onClick={() => setActiveMenu('audit-report')}
            // activeMenu={activeMenu}
          >
            <Menu 
              title="ISO"
              to="/audit-module/audit-report/iso/iso" 
            />
            <Menu 
              title="MSPO Part 2"
              to="/audit-module/audit-report/mspo/part-2" 
            />
            <Menu 
              title="MSPO Part 3"
              to="/audit-module/audit-report/mspo/part-3" 
            />
            <Menu 
              title="MSPO Part 4"
              to="/audit-module/audit-report/mspo/part-4" 
            />
            <Menu 
              title="MSPO-SCCS"
              to="/audit-module/audit-report/mspo-sccs/mspo-sccs" 
            />
          </Menu>
        </Menu>

        <Menu 
          title="Review"
          icon="book-open"
          to="/review"
          // onClick={() => setActiveMenu('review')}
          // activeMenu={activeMenu}
        >
          {/* <Menu 
            title="Audit Summary"
            to="/review/audit-summary" 
          /> */}
          <Menu 
            title="Operation Review"
            to="/review/operation-review" 
          />
          <Menu 
            title="Peer Review"
            to="/review/peer-review" 
          />
          <Menu 
            title="Certification Approval Panel (CAP)"
            to="/review/cap" 
          >
            <Menu 
              title="ISO"
              to="/review/cap/iso" 
            />
            <Menu 
              title="MSPO"
              to="/review/cap/mspo" 
            />
          </Menu>
        </Menu>

        

             
      </ul>
    </React.Fragment>
  );
}

export default AuditorMenu;